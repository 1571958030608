import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      "feature_unlimited" : "No limits, no boundaries, no hidden agendas. Get access to everything you pay for.",
      "feature_payment"   : "Multiple payment solutions with fast credits to your account.",
      "feature_layout"    : "Grid, tile, list, etc. Choose Store layouts that work best for you.",
      "feature_tool"      : "Help us help you grow with marketing tools at your fingertips.",
      "feature_analytics" : "In-depth data on a user-friendly dashboard to help track your Shift activity.",
      "feature_domain"    : "We’ll help you find a URL that’s uniquely you.",



      "En": "1",
      "ContactUs": "Contact us",
      "Features": "Features",
      "Vision": "Vision",
      "About": "About",
      "Home": "Home",
      "ShiftYour": "shift your business now!",
      "WithShift": "With shift you will move to the next level in the online business space.",
      "Cool": "Cool?",
      "HitIt": "Hit it!",
      "AllYou": "All you need to know about us..",
      "Lorem": "lorem ipsum , you can put your text here",
      "AlsoOur": "Also, our accountant is a part-time magician that can pull bunnies and stuff.",
      "OurVision": "Our vision..",
      "IsToSee": "is to see you sign up.",
      "AndHere": "And Here’s Our Unmatched  Service ",
      "Unlimited": "Unlimited",
      "Everything": "Everything",
      "Payment": "Payment ",
      "Online": "Online ",
      "Multiple": "Multiple ",
      "Layouts": "Layouts ",
      "Marketing": "Marketing ",
      "Tools": "Tools ",
      "Sales": "Sales  ",
      "Analytics": "Analytics  ",
      "Private": "Private ",
      "Domain": "Domain ",
      "TalkTo": "Talk to us …",
      "Name": "Name",
      "Mobile": "Mobile",
      "Email": "E-mail",
      "TakeOur": "Take our breath away …",
      "Send": "Send",
      "CreateAnd": "Create and launch",
      "YourName": "Your name",
      "BusinessName": "Business name (or Instagram account)",
      "Password": "Password",
      "AcceptTerms": "Accept Terms & Privacy Policy",
      "Next": "Next",
      "Monthly": "monthly",
      "Yearly": "yearly",
      "DoYou": "Do you have a domain name?",
      "TypeYour": "Type your current domain",
      "PickAdomain": "Pick a domain name: www.yourname.com",
      "Yes": "Yes",
      "No": "No",
      "validPhoneError" : "Your phone number is invalid",
      "validEmailError" : "Your email is invalid",
      "validPasswordError" : "Your password must be atleast 8 characters long",
      "PayAnd": "Pay and Start",
      "Privacy": "Privacy and Policy '\n'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
    }
  },
  ar: {
    translation: {
      "feature_unlimited" : "لا حدود للمنتجات، لا حدود للطلبات، لا ميزات مخفية، لديك إمكانية الوصول لكل الخدمات التي نقدمها لك منذ اليوم الأول.",
      "feature_payment"   : "طرق دفع متنوعة مع التحويل السريع لحسابك البنكي!",
      "feature_layout"    : "قوائم، أقسام، وغيرها من طرق العرض التي تستخدمها كواجهة متجرك الالكتروني.",
      "feature_tool"      : "استخهدم طرق التسويق المتعددة والمتجددة في تسويق منتجاتك.",
      "feature_analytics" : "إحصائيات دقيقة وشاملة لمتابعة مبيعاتك وطلباتك أولاً بأول.",
      "feature_domain"    : "سنساعدك في حجز اسم موقعك الخاص في حال توفره للحجز.",

      "validPhoneError" : "رقم الهاتف غير صحيح",
      "validEmailError" : "البريد الإلكتروني غير صحيح",
      "validPasswordError" : "كلمة المرور يجب أن تتكون من 8 أحرف على الأقل",
      "En": "0",
      "ContactUs": "تواصل ويانا",
      "Features": "المميزات",
      "Vision": "رؤيتنا",
      "About": "اخذ فكرة! ",
      "Home": "الرئيسية",
      "ShiftYour": "ودك تبيع؟!",
      "WithShift": "مع شفت راح ننقلك نقلة نوعية في عالم التجارة  الالكترونية",
      "Cool": "خوش؟",
      "HitIt": "بلش!",
      "AllYou": "ماذا تود أن تعرف؟",
      "Lorem": "lorem ipsum ، يمكنك وضع النص الخاص بك هنا",
      "AlsoOur": "وواحد فينا عليه جاي مخدر مو صاحي.",
      "OurVision": "رؤيتنا..",
      "IsToSee": "انك تسجل / تسجلين.",
      "AndHere": "وهذي خدماتنا",
      "Unlimited": "كل شي",
      "Everything": "ماله حد",
      "Payment": "دفع",
      "Online": "الكتروني",
      "Multiple": "طرق عرض",
      "Layouts": "مختلفة",
      "Marketing": "أدوات",
      "Tools": "تسويقية",
      "Sales": "احصائيات",
      "Analytics": "جباااارة",
      "Private": "اسم خاص",
      "Domain": "بموقعك",
      "TalkTo": "قول تدلل وسولف..",
      "Name": "الاسم",
      "Mobile": "التلفون",
      "Email": "الايميل",
      "TakeOur": "يلا أتحفنا ..",
      "Send": "أرسل",
      "CreateAnd": "سجل وانطلق",
      "YourName": "اسمك",
      "BusinessName": "اسم مشروعك بالانجليزي (او الانستقرام)",
      "Password": "الباسورد",
      "AcceptTerms": "أوافق على شروط التسجيل والاستخدام",
      "Next": "التالى",
      "Monthly": "شهري",
      "Yearly": "سنوي",
      "DoYou": "عندك اسم خاص بالموقع؟",
      "TypeYour": "اكتب اسم موقعك:",
      "PickAdomain": "شنو الاسم اللي بخاطرك؟",
      "Yes": "عندي",
      "No": "لأ",
      "PayAnd": "ادفع وابدأ",
      "Privacy": "الخصوصية والسياسة لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة.لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري.تم نشره في الستينيات من القرن الماضي مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.الجزء القياسي من لوريم إيبسوم المستخدم منذ القرن الخامس عشر مستنسخ أدناه للمهتمين. تم أيضًا نسخ القسمين 1.10.32 و 1.10.33 من de Finibus Bonorum et Malorum بواسطة Cicero في شكلهما الأصلي بالضبط ، مصحوبة بنسخ إنجليزية من ترجمة عام 1914 بواسطة H. Rackham.",
    }
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
  });
export default i18n;