import React from 'react'
import ScreenWrapper from '../components/ScreenWrapper'
import { Link } from 'react-router-dom'
//icons
import storeIcon from '../images/store.svg'
import offerIcon from '../images/offer.svg'
// Languages:
import { useTranslation } from 'react-i18next'
import axios from 'axios';

const Signup2 = () => {
    const { t } = useTranslation()
    const [hasDomain, setHasDomain] = React.useState('yes');
    const [domain, setDomain] = React.useState(null);
    const [paymentMethod, setPaymentMethod] = React.useState("knet");
    const [paymentPlan, setPaymentPlan]     = React.useState("yearly");
    
    const handleColorChange = (e) => 
    {
        if(e == 1 )
        {

        }
        else
        {

        }
        var storeDiv = document.getElementById("storeDiv")
        var storeDiv_h3 = document.getElementById("storeDiv_h3")
        var offerDiv = document.getElementById("offerDiv")
        var offerDiv_h3 = document.getElementById("offerDiv_h3")
        storeDiv.className = e == 1 ? "sign__item__active" : "sign__item"
        storeDiv_h3.className = e == 1 ? "sign__item__h3__active" : ""
        offerDiv.className = e == 1 ? "sign__item" : "sign__item__active"
        offerDiv_h3.className = e == 1 ? "" : "sign__item__h3__active"
    }

    const registerData = (event) => {
        event.preventDefault()

        var data = localStorage.getItem("data")
        data = JSON.parse(data)
        const mainData = {
                name        : data.name,
                email       : data.email,
                businessName: data.insta,
                instagram   : "",
                mobile      : data.mobile,
                domain      : domain,
                paymentMethod   :paymentMethod,
                paymentPlan     :paymentPlan,
                password    : data.password,
                hasDomain : hasDomain
        }

        var config = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: false
         }
     
         var payload = JSON.stringify(mainData);
         console.log(payload)
         axios.post("https://us-central1-tryshiftt.cloudfunctions.net/api/v1/registerUser", mainData, config)
            .then(res => {
               var response = res.data;
               console.log(response)
               if(response.error == false)
               {
                 window.location = response.data;
               }
               else
               {
                 alert("Unable to register. Please check your credentials")
               }            
            })
            .catch((error) => {
               
             alert("Unable to register. Please check your credentials")
     
            });
    }
    
    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <h1 className="h1-heading">{t('CreateAnd')}</h1>
            <div className="sign__list">
                <div className="sign__item" id="storeDiv" onClick={() => handleColorChange(1)}>
                    <img src={storeIcon} alt="storeimg" />
                    <h3 id="storeDiv_h3">25 KWD<br></br>{t('Monthly')}</h3>
                </div>
                <div className="sign__item__active" id="offerDiv" onClick={() => handleColorChange(2)}>
                    <img src={offerIcon} alt="offerimg" />
                    <h3 className="sign__item__h3__active" id="offerDiv_h3">250 KWD<br></br>{t('Yearly')}</h3>
                </div>
            </div>
            <div className="sign__check">
                <h3>{t('DoYou')}</h3>
                <div className="form-radio">
                    <input type="radio" name="domain" id="yes" onClick={() => setHasDomain('yes')} checked={hasDomain == 'yes' ? true : false} readOnly />
                    <label htmlFor="yes">{t('Yes')}</label>
                </div>
                <div className="form-radio">
                    <input type="radio" name="domain" id="no" onClick={() => setHasDomain('no')} checked={hasDomain == 'no' ? true : false} readOnly />
                    <label htmlFor="no">{t('No')}</label>
                </div>
            </div>
            <input type="text" placeholder={hasDomain == "yes" ? t('TypeYour') : t('PickAdomain')} className="input-el"  onChange={(e) => 
                    { 
                        setDomain(e.target.value)
                    }}/>
            <Link onClick={ (event) => 
            {
                registerData(event)                
            } 
            } className="our-btn" to="/">{t('PayAnd')}</Link>
        </ScreenWrapper>
    )
}

export default Signup2