import React from 'react'
import { Link } from 'react-router-dom'
import ScreenWrapper from '../components/ScreenWrapper'
import Colors from '../constants/Colors'
import ueaLogo from '../images/uaelg.png'
// Languages:
import { useTranslation } from 'react-i18next'

const Signup1 = () => {
    
    const { t, i18n } = useTranslation()
    const [selected, setSelected] = React.useState(false)
    const [name, setName] = React.useState(null)
    const [insta, setInsta] = React.useState(null)
    const [phone, setPhone] = React.useState(null)
    const [email, setEmail] = React.useState(null)
    const [password, setPassword] = React.useState(null)

    const [emailValid, setEmailValid] = React.useState(null)
    const [phoneValid, setPhoneValid] = React.useState(null)
    const [passwordValid, setPasswordValid] = React.useState(null)


    const selectItem = () => {
        document.getElementById('check1').setAttribute("checked", true)
        setTimeout(() => {
            setSelected(true)
        }, 10);
    }
    const closeItem = () => {
        setSelected(false)
        setTimeout(() => {
            document.getElementById('check1').setAttribute("checked", true)
        }, 100);
    }

    const setRegisterData = (event) => 
    {
        var isPasswordValid = false
        var isEmailValid = false
        var isPhoneValid = false
        console.log(phone)
        if(/[569]\d{7}$/.test(phone)){
            isPhoneValid = (true)
        }else{
            isPhoneValid = (false)
        }

        if(password != null && password.length > 7){
            isPasswordValid = (true)
        }else{
            isPasswordValid = (false)
        }

        isEmailValid = (true)
        var tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email ) 
        {
            isEmailValid = (false)
        }
        else if (email.length > 254) 
        {
            isEmailValid = (false)
        }
        else
        {
            var valid = tester.test(email);
            if (!valid) 
                isEmailValid = (false)
    
                // Further checking of some things regex can't handle
            var parts = email.split("@");
            if (parts[0].length > 64) 
                isEmailValid = (false)
    
            var domainParts = parts[1].split(".");
            if (domainParts.some(function (part) 
            {
                if(part.length > 63)
                    isEmailValid = (false)
                }
            )) 
                isEmailValid = (false)
        }        

        setEmailValid(isEmailValid)
        setPasswordValid(isPasswordValid)
        setPhoneValid(isPhoneValid)

        if(isPhoneValid && isEmailValid && isPasswordValid)
        {
            const data = {
                name:name,
                email:email,
                businessName: insta,
                mobile:  "+965" + phone,
                password:password
            }            
            localStorage.setItem("data", JSON.stringify(data))
            //this.props.data = "true"
        }
        else
            event.preventDefault()
    }

    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <h1 className="h1-heading">{t('CreateAnd')}</h1>
            {!selected &&
                <form action="" className="form">
                    <input type="text" value={name} placeholder={t('YourName')} className="input-el" onChange={(e) => 
                        { 
                            setName(e.target.value)
                        }}/>
                    <input type="text" value={insta} placeholder={t('BusinessName')} className="input-el" onChange={(e) => 
                        { 
                            setInsta(e.target.value)
                        }}/>
                    <div className="phone-input">
                        <div className="country">
                            <img src={ueaLogo} alt="uaelogo" />
                            <span>+965</span>
                        </div>
                        <input value={phone} maxLength="10" type="text" placeholder="00000000" className="input-el" onChange={(e) => 
                        { 
                            setPhone(e.target.value)
                        }}/>
                    </div>
                    {phoneValid == false && <div style={{marginTop:"-8px", marginBottom: "10px"}}><span style={{color:"red"}}>{t('validPhoneError')}</span></div>}

                    <input value={email} type="email" placeholder={t('Email')} className="input-el" 
                    onChange={(e) => 
                    { 
                        setEmail(e.target.value)
                    }} />
                    {emailValid == false && <div style={{marginTop:"-8px", marginBottom: "10px"}}><span style={{color:"red"}}>{t('validEmailError')}</span></div>}
                    <input value={password} type="password" minLength="8" placeholder={t('Password')} className="input-el" onChange={(e) => 
                    { 
                        setPassword(e.target.value)
                    }}/>
                    {passwordValid == false && <div style={{marginTop:"-8px", marginBottom: "10px"}}><span style={{color:"red"}}>{t('validPasswordError')}</span></div>}
                    <div className="form-check">
                        <input style={{cursor: "pointer"}} type="checkbox" id="check1" onClick={() => selectItem()} />
                        <label style={{ color: Colors.main, fontSize: "12px", cursor: "pointer" }} htmlFor="check1">{t('AcceptTerms')}</label>
                    </div>
                </form>}
            {selected &&
                <div className="feature__el">
                    <h3>{t('Privacy')}</h3>
                    <button onClick={() => closeItem()}>X</button>
                </div>
            }
            <Link onClick={ (event) => 
            {
                setRegisterData(event)                
            } 
            } className="our-btn" to="/finalize">{t('Next')}</Link>
        </ScreenWrapper>
    )
}

export default Signup1
