import React from 'react'
import { Link } from 'react-router-dom'
import ScreenWrapper from '../components/ScreenWrapper'
// Languages:
import { useTranslation } from 'react-i18next'
import axios from 'axios';

const Contact = () => {
    const { t, i18n } = useTranslation()
    const [name  , setName] = React.useState("");
    const [mobile, setMobile] = React.useState("");
    const [email , setEmail] = React.useState("");
    const [desc , setDesc] = React.useState("");

    const sendEmail = (event) => {
        event.preventDefault()

        const mainData = {
                name        : name,
                email       : email,
                mobile      : mobile,
                desc        : desc
        }

        if(email.length < 2 || name.length < 2 || mobile.length < 2)
        {
            alert("Please input valid details")
            return
        }

        var config = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: false
         }
     
         var payload = JSON.stringify(mainData);
         console.log(payload)
         axios.post("https://us-central1-tryshiftt.cloudfunctions.net/api/v1/sendContactUs", mainData, config)
            .then(res => {
               var response = res.data;
               console.log(response)
               if(response.error == false)
               {
                  alert(response.message)
                  setEmail("")
                  setMobile("")
                  setDesc("")
                  setName("")
                }
               else
               {
                 alert("Unable to connect. Please try again later")
               }            
            })
            .catch((error) => {                
                alert("Unable to connect. Please try again later")
     
            });
    }
    

    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <div className="contact">
                <h1 className="h1-heading">{t('TalkTo')}</h1>
                <form action="" className="about-form">
                    <div className="contact__form-item">
                        <input type="text" placeholder={t('Name')} className="input-el" onChange={(e) => 
                        { 
                            setName(e.target.value)
                        }}/>
                        <input type="text" placeholder={t('Mobile')} className="input-el" onChange={(e) => 
                        { 
                            setMobile(e.target.value)
                        }}/>
                    </div>
                    <input type="email" placeholder={t('Email')} className="input-el" onChange={(e) => 
                        { 
                            setEmail(e.target.value)
                        }}/>
                    <textarea cols="20" rows="6" className="input-el" maxLength="500" placeholder={t('TakeOur')} onChange={(e) => 
                        { 
                            setDesc(e.target.value)
                        }}></textarea>
                </form>
                <Link onClick={ (event) => 
                    {
                        sendEmail(event)                
                    } 
                    } className="our-btn our-btn-outline">{t('Send')}</Link>
            </div>
        </ScreenWrapper>
    )
}

export default Contact
