import React from 'react'
import { Link } from 'react-router-dom'
import ScreenWrapper from '../components/ScreenWrapper'
// Languages:
import { useTranslation } from 'react-i18next'

const About = () => {
    const { t, i18n } = useTranslation()
    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <h1 className="h1-heading">{t('AllYou')}</h1>
            <p className="p-text">{t('WeProvide')}</p>
            <p className="p-text">{t('AlsoOur')}</p>
            <Link className="our-btn" to="/register">{t('HitIt')}</Link>
        </ScreenWrapper>
    )
}

export default About
