import React from 'react'
import ScreenWrapper from '../components/ScreenWrapper'
import { Link } from 'react-router-dom'
// Languages:
import { useTranslation } from 'react-i18next'

const Vision = () => {
    const { t } = useTranslation()
    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <h1 className="h1-heading">{t('OurVision')}</h1>
            <p className="p-text">{t('IsToSee')}</p>
            <Link className="our-btn" to="/register">{t('HitIt')}</Link>
        </ScreenWrapper>
    )
}

export default Vision
