import React from 'react'
import { useTranslation } from 'react-i18next'

export const ContextApp = React.createContext()
const getLanguageInStorage = () => localStorage.getItem('languageChoose') ? JSON.parse(localStorage.getItem('languageChoose')) : 'ar'

const ContextProvider = ({ children }) => {
    const { i18n } = useTranslation()
    const [isEng, setIsEng] = React.useState(getLanguageInStorage() == 'en' ? true : false)
    const [isAr, setIsAr] = React.useState(getLanguageInStorage() == 'ar' ? true : false)

    React.useEffect(() => {
        i18n.changeLanguage(getLanguageInStorage())
        console.log()
    }, [])

    const toggleLanguage = (type) => {
        if (type === 'ar') {
            setIsAr(true)
            setIsEng(false)
            localStorage.setItem('languageChoose', JSON.stringify(type))
        }
        if (type === 'en') {
            setIsEng(true)
            setIsAr(false)
            localStorage.setItem('languageChoose', JSON.stringify(type))
        }
    }

    return (
        <ContextApp.Provider value={{ toggleLanguage: toggleLanguage, isEng: isEng, isAr: isAr }}>
            {children}
        </ContextApp.Provider>
    )
}

export default ContextProvider
