import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './components/Navbar'

/*Pages*/
import Home from './pages/Home'
import About from './pages/About'
import Vision from './pages/Vision'
import Contact from './pages/Contact'
import Signup1 from './pages/Signup1'
import Signup2 from './pages/Signup2'
import Feature from './pages/Feature'
/*Context */

const App = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/vision" exact component={Vision} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/features" exact component={Feature} />
        <Route path="/register" exact component={Signup1} />
        <Route path="/finalize" exact component={Signup2} />
      </Switch>
    </Router>
  )
}

export default App
