import React from 'react'

import ScreenWrapper from '../components/ScreenWrapper'
import { Link } from 'react-router-dom'
//icons
import listIcon from '../images/lists.svg'
import privateIcon from '../images/private.svg'
import paymentIcon from '../images/payment.svg'
import salesIcon from '../images/sales.svg'
import domainIcon from '../images/domain.svg'
import marketingcon from '../images/marketing.svg'

import { useTranslation } from 'react-i18next'

const Feature = () => {
    const { t, i18n } = useTranslation()
    const [features, setFeatures] = React.useState([
        { id: 1, name: "Unlimited Everything", icon: listIcon, text: 'feature_unlimited' },
        { id: 2, name: "Payment Online", icon: paymentIcon, text: "feature_payment" },
        { id: 3, name: "Multiple Layouts", icon: privateIcon, text: "feature_layout" },
        { id: 4, name: "Marketing Tools", icon: marketingcon, text: "feature_tool" },
        { id: 5, name: "Sales Analytics", icon: salesIcon, text: "feature_analytics" },
        { id: 6, name: "Private Domain", icon: domainIcon, text: "feature_domain" },
    ])
    const [selected, setSelected] = React.useState(false)
    const [selectedItemObj, setSelectedItemObj] = React.useState({
        name: "",
        icon: "",
        text: ""
    })
    const selectItem = el => {
        setSelected(true)
        setSelectedItemObj({
            name: el.name,
            icon: el.icon,
            text: el.text
        })
    }
    const closeItem = () => {
        setSelected(false)
    }
    return (
        <ScreenWrapper dir={t('En') === '1' ? '' : 'rtl'}>
            <div className="features">
                <h1 className="h1-heading">{t('AndHere')}</h1>
                {!selected && <div className="features__list__reverse">
                    {features.map(el => (
                        <div className="feature__item" key={el.id} onClick={() => selectItem(el)}>
                            <img src={el.icon} alt={el.name} />
                            <h3>{t(el.name.split(" ")[0])} </h3>
                            <h3>{t(el.name.split(" ")[1])} </h3>
                        </div>
                    ))}
                </div>}
                {selected && <div className="feature__el">
                    <img src={selectedItemObj.icon} alt={selectedItemObj.name} />
                    <h3>{t(selectedItemObj.name.split(" ")[0])} {t(selectedItemObj.name.split(" ")[1])}</h3>
                    <p className="p-text">{t(selectedItemObj.text)}</p>
                    <button onClick={() => closeItem()}>X</button>
                </div>}
                <Link className="our-btn" to="/register">{t('HitIt')}</Link>
            </div>
        </ScreenWrapper>
    )
}

export default Feature
