import React from 'react'
import personImage from '../images/person.png'

const ScreenWrapper = ({ children, ...props }) => {
    return (
        <section className="screen">
            <div className="small-container h-100">
                <div className="screen-wrapper">
                    <div className="screen-img">
                        <img src={personImage} alt="Personimg" />
                    </div>
                    <div className="screen-content" dir={props.dir}>
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenWrapper
