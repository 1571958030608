import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.svg'
import { useTranslation } from 'react-i18next'
import { ContextApp } from '../context'

const Navbar = () => {

    const { toggleLanguage, isAr, isEng } = React.useContext(ContextApp)
    const { t, i18n } = useTranslation()
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleNavbar = () => setIsOpen(!isOpen)
    const closeNavbar = () => setIsOpen(false)
    const changeLng = (type) => {
        toggleLanguage(type)
        i18n.changeLanguage(type)
    }

    return (
        <nav className="navbar">
            <div className="full-container">
                <div className="navbar-wrapper">
                    <Link to="/" className="logo">
                        <img src={logo} alt="logo" />
                    </Link>
                    <ul className={`${isEng?'navbar__list':'navbar__list__reverse'} ${isOpen && "show"}`} >
                        <li className="navbar__item"><Link onClick={closeNavbar} className="navbar__link" to="/">{t('Home')}</Link></li>
                        <li className="navbar__item"><Link onClick={closeNavbar} className="navbar__link" to="/about">{t('About')}</Link></li>
                        <li className="navbar__item"><Link onClick={closeNavbar} className="navbar__link" to="/vision">{t('Vision')}</Link></li>
                        <li className="navbar__item"><Link onClick={closeNavbar} className="navbar__link" to="/features">{t('Features')}</Link></li>
                        <li className="navbar__item"><Link onClick={closeNavbar} className="navbar__link" to="/contact">{t('ContactUs')}</Link></li>
                        <li className="navbar__item"></li>
                    </ul>
                    <button type="button" onClick={() => { isEng ? changeLng('ar') : changeLng('en') }} className="navbar__btn">{isEng ? "Arabic" : "English"}</button>
                    <div className="hide menu-btn" onClick={() => toggleNavbar()}>
                        <div className="line line-1"></div>
                        <div className="line line-2"></div>
                        <div className="line line-3"></div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
